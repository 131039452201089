<template>
  <div id="user-list">
    <!-- app drawer -->
    <!-- Add New Snowflake Users -->
    <user-list-add-new
      v-model="isAddNewUserSidebarActive"
      :role-options="userRolesDropdown"
    ></user-list-add-new>
    <!-- Add New App Users From Snowflake Users -->
    <add-new-app-user
      v-if="addNewUserSidebar"
      :add-new-user-sidebar="addNewUserSidebar"
      :user-data-for-app-user="userDataForAppUser"
      @close-sidebar="addNewUserSidebar = false"
    ></add-new-app-user>

    <!-- user total card -->
    <v-row
      v-if="$store.state.snowflakeData.snowflakeUsers"
      class="mb-5"
    >
      <v-col
        v-for="total in snowflakeUserTotals"
        :key="total.title"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="total.color"
            >
              <v-icon
                size="30"
                color="white"
                class="rounded-0"
              >
                {{ total.icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter Snowflake Users
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="userRolesFilter"
            placeholder="Select Role"
            :items="userRolesDropdown"
            item-text="title"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <!-- Metric Filters -->
        <v-col sm="3">
          <v-select
            v-model="dateRangeSelection"
            item-color="primary"
            :items="dateSelectionOptions"
            dense
            :prepend-inner-icon="icons.mdiFilterVariant"
            outlined
            filled
            rounded
            hide-details
            @change="changeDateFilter"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="secondary"
            class="mb-4 mr-3"
            text
            @click="refreshSnowflakeUsersAndRoles()"
          >
            <v-icon
              left
            >
              {{ icons.mdiRefresh }}
            </v-icon>
            Refresh
          </v-btn>
          <v-btn
            color="primary"
            class="mb-4 me-3"
            text
            @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive"
          >
            <v-icon left>
              {{ icons.mdiAccountPlus }}
            </v-icon>
            Add Snowflake User
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-if="$store.state.snowflakeData.snowflakeUsers"
        :headers="tableColumns"
        :items="snowflakeUsers"
        :loading="loading"
        :items-per-page="10"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <!-- name -->
        <template #[`item.user`]="{item}">
          <router-link
            :to="{ name : 'snowflake-user', params : { id : parseIdForURL(item.id) } }"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            <div class="d-flex align-center">
              <v-avatar
                :color="item.avatar ? '' : 'primary'"
                :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                size="32"
              >
                <v-img
                  v-if="item.avatar"
                  :src="require(`@/assets/images/avatars/${item.avatar}`)"
                ></v-img>
                <span
                  v-else
                  class="font-weight-medium"
                >{{ avatarText(item.displayName ? item.displayName : item.loginName) }}</span>
              </v-avatar>

              <div class="d-flex flex-column ms-3">
                {{ item.displayName }}

                <small>@{{ item.loginName }}</small>
              </div>
            </div>
          </router-link>
        </template>

        <!-- enabled -->
        <template #[`item.enabled`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon
                size="18"
                color="primary"
              >
                {{ item.enabled ? icons.mdiCheckOutline : null }}
              </v-icon>
            </v-avatar>
          </div>
        </template>

        <!-- recent_consumption -->
        <template
          #[`item.recentConsumption`]="{item}"
        >
          <v-tooltip
            v-if="item.totalDuration"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                small
                :class="consumptionChipsProps(item.consumptionBucket)"
                class="v-chip-light-bg font-weight-semibold text-capitalize mr-3"
                v-on="on"
              >
                {{ [item.totalDuration, 'milliseconds'] | duration('humanize') }}
              </v-chip>
            </template>
            <span>Total Consumption Time {{ dateRangeFilter.name }}: <strong>{{ [item.totalDuration, 'milliseconds'] | duration('humanize') }}</strong></span>
          </v-tooltip>
          <v-tooltip
            v-if="item.executionTotalsAbbr"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                small
                :class="consumptionChipsProps(item.executionBucket)"
                class="v-chip-light-bg font-weight-semibold text-capitalize mr-3"
                v-on="on"
              >
                {{ item.executionTotalsAbbr }}
              </v-chip>
            </template>
            <span>Total Query Executions {{ dateRangeFilter.name }}: <strong>{{ item.executionTotalsAbbr }}</strong></span>
          </v-tooltip>
          <v-tooltip
            v-if="item.totalCost"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                small
                :class="consumptionChipsProps(item.consumptionBucket)"
                class="v-chip-light-bg font-weight-semibold text-capitalize"
                v-on="on"
              >
                {{ formatCurrency(item.totalCost) }}
              </v-chip>
            </template>
            <span>Total APPROXIMATE COST {{ dateRangeFilter.name }}: <strong>{{ formatCurrency(item.totalCost) }}</strong></span>
          </v-tooltip>
        </template>

        <!-- LAST_LOGIN -->
        <template #[`item.lastLogin`]="{item}">
          <div
            v-if="item.lastLogin"
            class="d-flex align-center"
          >
            <span class="text-capitalize"><small>{{ item.lastLogin.toISOString() | moment("from") }}</small></span>
          </div>
        </template>

        <!-- role -->
        <template #[`item.role`]="{item}">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-flex align-center"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar
                  size="30"
                  color="primary"
                  class="v-avatar-light-bg primary--text me-3"
                >
                  <v-icon
                    size="18"
                    color="primary"
                  >
                    {{ icons.mdiAccountOutline }}
                  </v-icon>
                </v-avatar>
                <span class="text-capitalize">{{ item.defaultRole }}</span>
              </div>
            </template>
            <span>User's Default Role: <strong>{{ item.defaultRole }}</strong></span>
            <p></p>
            <p>All Roles Applied To This User:</p>
            <p
              v-for="role in item.roles"
              :key="role"
            >
              <strong>{{ role }}</strong>
            </p>
          </v-tooltip>
        </template>

        <!-- hasPassword -->
        <template #[`item.hasPassword`]="{item}">
          <div class="d-flex align-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  size="30"
                  color="info"
                  class="v-avatar-light-bg primary--text me-3"
                  v-on="on"
                >
                  <v-icon
                    size="18"
                    color="info"
                  >
                    {{ item.hasPassword ? icons.mdiSnowflake : null }}
                  </v-icon>
                </v-avatar>
              </template>
              <span>{{ item.hasPassword ? 'User HAS a Snowflake Password' : 'User DOES NOT have a Snowflake Password' }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  size="30"
                  color="primary"
                  class="v-avatar-light-bg primary--text me-3"
                  v-on="on"
                >
                  <v-icon
                    size="18"
                    color="primary"
                  >
                    {{ item.appUserId ? icons.mdiKeyVariant : null }}
                  </v-icon>
                </v-avatar>
              </template>
              <span>{{ item.appUserId ? 'User HAS ' + serverConfig.name + ' Access' : 'User DOES NOT have ' + serverConfig.name + ' Access' }}</span>
            </v-tooltip>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'snowflake-user',params:{ id: parseIdForURL(item.loginName)}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                    color="info"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span class="info--text">User Profile</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click="updateSnowflakeUser(item.loginName, 'disabled', item.enabled ? true : false, item.enabled ? 'User Successfully Disabled' : 'User Successfully Enabled')">
                  <v-icon
                    size="20"
                    class="me-2"
                    color="error"
                  >
                    {{ item.enabled ? icons.mdiAccountOff : icons.mdiAccountCheck }}
                  </v-icon>
                  <span class="error--text">{{ item.enabled ? 'Disable Snowflake Access' : 'Enable Snowflake Access' }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="!item.appUserId"
                link
              >
                <v-list-item-title @click="createAppUser(item)">
                  <v-icon
                    size="20"
                    class="me-2"
                    color="primary"
                  >
                    {{ icons.mdiShieldAccountOutline }}
                  </v-icon>
                  <span class="primary--text">Create {{ serverConfig.name }} User</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="showSnackBarDialog"
      color="primary"
    >
      <span><strong>{{ snackBarMessage }}</strong></span>
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="showSnackBarDialog = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
/* eslint-disable object-curly-newline */
// eslint-disable-next-line object-curly-newline
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { formatCurrency } from '@/functions'
import { alterSnowflakeUser, refreshSnowflakeUsersAndRoles } from '@/snowflake/snowflakeAdministration'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import {
  mdiAccountCheck,
  mdiAccountCircleOutline,
  mdiAccountMultipleOutline,
  mdiAccountOff,
  mdiAccountOutline,
  mdiAccountPlus,
  mdiAccountStarOutline,
  mdiCheckOutline,
  mdiCloseOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiFilterVariant,
  mdiKeyVariant,
  mdiRefresh,
  mdiShieldAccountOutline,
  mdiSnowflake,
  mdiSquareEditOutline,
} from '@mdi/js'
import serverConfig from '@serverConfig'
// eslint-disable-next-line object-curly-newline
import { computed, onMounted, ref, watchEffect } from '@vue/composition-api'
import _ from 'lodash'
import AddNewAppUser from '../../administration/AddNewUser.vue'
import UserListAddNew from './UserListAddNew.vue'

// import userStoreModule from '../userStoreModule'

export default {
  components: {
    UserListAddNew,
    AddNewAppUser,
  },
  setup() {
    const sortBy = ref('recentConsumption')
    const sortDesc = ref(true)
    const search = ref('')
    const loading = ref(false)
    const userRolesFilter = ref(null)
    const showSnackBarDialog = ref(false)
    const snackBarMessage = ref(null)
    const addNewUserSidebar = ref(false)
    const userDataForAppUser = ref(null)
    const dateSelectionOptions = computed(() => store.state.dateRangeFilter.map(m => m.name))
    const dateRangeFilter = computed(() => store.state.dateRangeFilter.filter(f => f.active)[0])
    const dateRangeSelection = ref(dateRangeFilter.value.name)
    const changeDateFilter = value => {
      store.commit('setDateRangeFilter', value)
    }

    const parseIdForURL = id => encodeURI(id)

    const createAppUser = userData => {
      addNewUserSidebar.value = true
      userDataForAppUser.value = userData
    }

    watchEffect(() => {
      const bannerLoadingStatus = store.state.loadingBanner.users
      loading.value = bannerLoadingStatus
    })

    const updateSnowflakeUser = async (user, property, value, showMessage) => {
      try {
        loading.value = true
        const alterSnowflakeUserResponse = await alterSnowflakeUser(user, property, value)

        if (alterSnowflakeUserResponse[0]?.status && alterSnowflakeUserResponse[0]?.status.includes('successful')) {
          snackBarMessage.value = showMessage
          showSnackBarDialog.value = true
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        console.log('ERROR', error)
        let errorMessage = error
        if (error.includes('Insufficient privileges')) {
          const oauthRole = localStorage.getItem('snowflakeScope').split('role:')[1]
          const snowflakeRole = oauthRole || store.getters.user.role
          errorMessage = `Your current ${
            oauthRole ? 'OAuth' : ''
          } Role "${snowflakeRole}" has insufficient privileges to alter Snowflake Users. Only the role with the OWNERSHIP privilege on the user, or a higher role, can execute this command to modify most user properties. More Information: <a href="https://docs.snowflake.com/en/sql-reference/sql/alter-user.html" target="_blank">Visit Snowflake Docs</a>`
        }
        store.dispatch('systemMessages', `<strong>${errorMessage}</strong>`)
      }
    }

    const consumptionChipsProps = bucket => {
      if (!bucket) return null
      let classDesc = 'success--text'
      if (bucket === 'High') {
        classDesc = 'error--text'
      }
      if (bucket === 'Medium') {
        classDesc = 'warning--text'
      }

      // Hides Chip if Empty Data
      if (!bucket) {
        classDesc += ' d-none'
      }

      return classDesc
    }

    onMounted(async () => {
      refreshSnowflakeUsersAndRoles()
    })

    const roles = computed(() => store.state.snowflakeData.snowflakeRoles)

    const userRolesDropdown = computed(() => roles.value.map(m => m.name))

    const userConsumption = computed(() => store.getters['snowflakeData/snowflakeUserConsumptionByFilter'])

    const snowflakeUsers = computed(() => {
      const getUserData = store.getters['snowflakeData/snowflakeUsersAndRoles']

      let users = getUserData.map(m => m)

      // Run Filters on Users If Activated
      if (search.value) {
        users = users.filter(
          f =>
            (f.displayName && f.displayName.toLowerCase().includes(search.value.toLowerCase())) ||
            (f.loginName && f.loginName.toLowerCase().includes(search.value.toLowerCase())),
        )
      }

      if (userRolesFilter.value) {
        users = users.filter(f => f.roles && f.roles.includes(userRolesFilter.value))
      }

      // Add On Consumption
      users.forEach((user, i) => {
        const consumption = userConsumption.value.filter(f => f.id === user.id)[0]
        if (consumption) {
          users[i].totalCost = consumption.cost || 0
          users[i].totalDuration = consumption.duration || 0
          users[i].totalCredits = consumption.credits || 0
          users[i].totalExecutions = consumption.executions || 0
          users[i].executionTotalsAbbr = consumption.executionTotalsAbbr
          users[i].consumptionBucket = consumption.consumptionBucket
          users[i].executionBucket = consumption.executionBucket
        } else {
          users[i].totalCost = 0
          delete users[i].totalDuration
          delete users[i].totalCredits
          delete users[i].totalExecutions
          delete users[i].executionTotalsAbbr
          delete users[i].consumptionBucket
          delete users[i].executionBucket
        }
      })

      // Apply Dynamic Table Sorting
      const sortByKey = Array.isArray(sortBy.value) ? sortBy.value[0] : sortBy.value
      const sortDescKey = Array.isArray(sortDesc.value) ? sortDesc.value[0] : sortDesc.value
      if (sortByKey === 'recentConsumption') {
        users = _.orderBy(users, 'totalCost', sortDescKey ? 'desc' : 'asc')
      }
      if (sortByKey === 'user') {
        users = _.orderBy(users, users.displayName ? 'displayName' : 'loginName', sortDescKey ? 'desc' : 'asc')
      }
      if (sortByKey === 'lastLogin') {
        if (sortDescKey) {
          users = _.orderBy(
            users,
            // eslint-disable-next-line camelcase
            ({ lastLogin }) => lastLogin || 999999999999999,
            ['asc'],
          )
        } else {
          users = _.orderBy(
            users,
            // eslint-disable-next-line camelcase
            ({ lastLogin }) => lastLogin || 0,
            ['desc'],
          )
        }
      }
      if (sortByKey === 'role') {
        users = _.orderBy(users, 'defaultRole', sortDescKey ? 'desc' : 'asc')
      }

      return users
    })

    // const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    // })

    const snowflakeUserTotals = computed(() => {
      const userTotals = []
      const userList = store.state.snowflakeData.snowflakeUsers.length
      userTotals.push({
        title: 'Total Users',
        total: userList,
        icon: mdiAccountMultipleOutline,
        color: 'primary',
      })

      // Get Active Users
      const activeUsersCount = snowflakeUsers.value.filter(f => f.totalExecutions > 0).length
      userTotals.push({
        title: `Active Users (${dateRangeFilter.value.name})`,
        total: activeUsersCount,
        icon: mdiAccountStarOutline,
        color: 'success',
      })

      return userTotals
    })

    const isAddNewUserSidebarActive = ref(false)

    const tableColumns = [
      { text: 'USER', value: 'user' },
      { text: 'ENABLED', value: 'enabled' },
      { text: 'RECENT CONSUMPTION', value: 'recentConsumption' },
      { text: 'LAST LOGIN', value: 'lastLogin' },
      { text: 'DEFAULT ROLE', value: 'role' },
      { text: 'HAS CREDENTIALS', value: 'hasPassword', sortable: false },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    return {
      formatCurrency,
      dateRangeSelection,
      changeDateFilter,
      dateSelectionOptions,
      dateRangeFilter,
      parseIdForURL,
      userDataForAppUser,
      createAppUser,
      addNewUserSidebar,
      refreshSnowflakeUsersAndRoles,
      snackBarMessage,
      showSnackBarDialog,
      updateSnowflakeUser,
      consumptionChipsProps,
      sortBy,
      sortDesc,
      snowflakeUsers,
      userRolesDropdown,
      userRolesFilter,
      search,
      snowflakeUserTotals,
      tableColumns,
      loading,
      isAddNewUserSidebarActive,
      avatarText,
      serverConfig,

      // icons
      icons: {
        mdiAccountOff,
        mdiAccountCheck,
        mdiShieldAccountOutline,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiAccountPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiCheckOutline,
        mdiCloseOutline,
        mdiKeyVariant,
        mdiAccountCircleOutline,
        mdiRefresh,
        mdiSnowflake,
        mdiAccountMultipleOutline,
        mdiAccountStarOutline,
        mdiFilterVariant,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
